/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('slick-carousel');

import Plyr from 'plyr';

const app = (() => {
    let nav;
    let menu;
    let menuItems;
    const init = () => {
        nav = document.querySelector('header');
        menu = document.querySelector('.menu-icon');
        menuItems = document.querySelectorAll('.item');
        if (!menu)
            return;
        applyListeners();
    }

    const applyListeners = () => {
        menu.addEventListener('click', () => toggleClass(nav, 'nav-active'));
    }

    const toggleClass = (element, stringClass) => {
        if (element.classList.contains(stringClass))
            element.classList.remove(stringClass);
        else
            element.classList.add(stringClass);

    }


    init();
})();

(function ($) {
    "use strict";

    //Header – Slide up on scroll down, slide down on scroll up
    $(window).scroll(function (event) {
        var st = $(this).scrollTop();
        var logo = $(".logo");
        if (st > 50) {
            // downscroll code
            logo.css("top", "-250px");
            logo.css("position", "absolute");
        } else {
            // upscroll code
            logo.css("top", "0px");
            logo.css("position", "unset");
        }
    });

    window.addEventListener('load', function () {
        const player = new Plyr('#player', {
            'settings': ['captions', 'speed', 'quality', 'loop'],
            'captions': {active: true, language: 'auto', update: false}
        });

        // Expose player so it can be used from the console
        window.player = player;
        $('.accordion .collapse').collapse();
        // Add minus icon for collapse element which is open by default
        $(".collapse.show").each(function () {
            $(this).prev(".card-header").find(".fa").addClass("fa-minus").removeClass("fa-plus");
        });

        // Toggle plus minus icon on show hide of collapse element
        $(".collapse").on('show.bs.collapse', function () {
            $(this).prev(".card-header").find(".fa").removeClass("fa-plus").addClass("fa-minus");
        }).on('hide.bs.collapse', function () {
            $(this).prev(".card-header").find(".fa").removeClass("fa-minus").addClass("fa-plus");
        });

        $(".resources-listing-slider__container-wrapper").each(function () {
            var btnPrev = this.querySelector(".resources-listing-slider__btn--prev")
                , btnNext = this.querySelector(".resources-listing-slider__btn--next")
                , dots = this.querySelector(".resources-listing-slider__dots")
                , slider = $(".resources-listing-slider__slider");
            slider.slick({
                prevArrow: btnPrev,
                nextArrow: btnNext,
                focusOnChange: !0,
                accessibility: !0,
                variableWidth: !1,
                infinite: !1,
                dots: !0,
                rows: 0,
                appendDots: dots,
                mobileFirst: !0,
                arrows: !1,
                slidesToScroll: 1,
                slidesToShow: 1.2,
                responsive: [{
                    breakpoint: 960,
                    settings: {
                        dots: !1,
                        arrows: !0,
                        slidesToShow: 2.3,
                        centerMode: !1
                    }
                }, {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 3.3,
                        dots: !1,
                        arrows: !0
                    }
                }]
            })
        });

        // Slick multiple article relate
        $('#slick-article-relate').slick({
            centerMode: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: true,
            responsive: [{
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
                {
                    breakpoint: 567,
                    settings: {
                        centerMode: true,
                        infinite: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                        autoplaySpeed: 2000,
                        arrows: true,
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        centerMode: true,
                        infinite: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                        autoplaySpeed: 2000,
                        arrows: true,
                    }
                }]
        });

        // Slick partners
        $('.partners-world-slides').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            infinite: true,
            pauseOnFocus: true,
            pauseOnDotsHover: true,
            pauseOnHover: true,
            speed: 300,
            swipe: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                        arrows: false,
                    }
                }]
        });

        // Back to top action
        $(function () {
            $(document).ready(function () {

                return $(window).scroll(function () {
                    return $(window).scrollTop() > 300 ? $("#back-to-top-new").addClass("show") : $("#back-to-top-new").removeClass("show")
                }), $("#back-to-top-new").click(function () {
                    return $("html,body").animate({
                        scrollTop: "0"
                    })
                })
            })
        });


        // scrollspy
        $('a[href*="#"]')
            .not('[href="#"]')
            .not('[href="#0"]')
            .click(function (event) {
                // On-page links
                if (
                    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                    &&
                    location.hostname == this.hostname
                ) {
                    // Figure out element to scroll to
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top - 125 //225
                        }, 1000, function () {
                            // Callback after animation
                            // Must change focus!
                            var $target = $(target);
                            //console.log($target);
                            // $target.focus();
                            // if ($target.is(":focus")) { // Checking if the target was focused
                            //   return false;
                            // } else {
                            //   $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                            //   $target.focus(); // Set focus again
                            // };
                        });
                    }
                }
            });

        // VALIDATE FORM
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                } else {
                    $(this).find("button[type='submit']").prop('disabled', true);
                    $(this).find("button[type='submit']").html('<div class="spinner-border text-dark"></div>');
                }
                form.classList.add('was-validated');
            }, false);
        });

        if ($('#preloader').length) {
            $('#preloader').delay(100).fadeOut('slow', function () {
                $(this).remove();
            });
        }
        $(document).ready(function () {

            if ($(window).width() > 576) {
                if ($('.scrollTo').length) {
                    $('html, body').animate({scrollTop: $('.scrollTo').offset().top - 170}, 'slow');
                }
            } else {
                if ($('.scrollTo').length) {
                    $('html, body').animate({scrollTop: $('.scrollTo').offset().top - 50}, 'slow');
                }
            }
        });
    });
})(jQuery);
